import api from "@/api";
import { TableRow } from "@/models/type";
import axios from "axios";
import { ElMessageBox, ElNotification } from "element-plus";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Vue, Watch } from "vue-property-decorator";
import './index.scss'

export default class Address extends Vue {

  public ChinaList: Array<any> = []
  public HongkongList: Array<any> = []
  public MacaoList: Array<any> = []
  public TaiwanList: Array<any> = []
  public type = 2
  public listNum = 0
  public memberAddressList: Array<UmsAddress> = []
  public submitType = 'add'

  public submitForm: UmsAddress = {
    city: '',
    defaultStatus: 0,
    detailAddress: '',
    name: '',
    phoneNumber: '',
    postCode: '',
    province: '',
    region: '',
    address: []
  }
  public formRules: any = {
    name: [
      { required: true, message: '请输入收货人名称' }
    ],
    phoneNumber: [
      { required: true, message: '请输入您的手机号' }
    ],
    address: [
      { required: true, message: '请选择收货地址' }
    ],
    detailAddress: [
      { required: true, message: '请输入您的详细地址' }
    ]
  }

  public optionsList: Array<any> = []

  public tableList: Array<TableRow> = [
    {label: '收货人', prop: 'name'},
    {label: '所在地区', slot: {
      default: (scope: any): JSX.Element => <span>{scope.row.province} {scope.row.city} {scope.row.region}</span>
    }},
    {label: '详情地址', prop: 'detailAddress'},
    {label: '邮编', prop: 'postCode'},
    {label: '手机号', prop: 'phoneNumber'},
    {label: '操作', slot: {
      default: (scope: any): JSX.Element => <div
        class="address-btn"
      >
        <span class="address-btn-edit" onClick={() => this.addressEdit(scope.row)}>修改</span>
        <span class="address-btn-delete" onClick={() => this.addressDelete(scope.row.id)}>删除</span>
        {
          scope.row.defaultStatus
            ? <span class="address-btn-default">默认地址</span>
            : <span class="address-btn-nodefault" onClick={() => this.addressDefault(scope.row)}>设为默认</span>
        }

      </div>
    }},
  ]

  addressDefault(row: UmsAddress): void {
    const obj = Object.assign({}, row)
    obj.defaultStatus = 1
    api.memberAddressUpdate(obj).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '设置成功'
      })
      this.listNum++
    })
  }

  addressDelete(id: number): void {
    ElMessageBox.confirm('确定删除收货地址？', '删除收货地址', {
      type: 'warning'
    }).then(() => {
      api.memberAddressDelete(id).then(() => {
        ElNotification({
          type: 'success',
          title: '成功',
          message: '删除收货地址成功'
        })
        this.listNum++
      })
    }).catch(() => false)
  }

  @Watch('listNum')
  listNumChange(val: number): void {
    if(val) {
      this.getList()
    }
  }

  getJson(): void {
    Promise.all([
      axios({url: './data/China.json'}),
      axios({url: './data/Hongkong.json'}),
      axios({url: './data/Macao.json'}),
      axios({url: './data/Taiwan.json'}),
    ]).then(res => {
      this.ChinaList = res[0].data
      this.HongkongList = res[1].data
      this.MacaoList = res[2].data
      this.TaiwanList = res[3].data
      this.optionsList = this.HongkongList
    })
  }

  addressEdit(row: UmsAddress): void {
    const obj = Object.assign({}, row)
    if(['香港特别行政区', '澳门特别行政区', '台湾省'].includes(obj.province as string)) {
      this.type = this.getTypeName(obj.province as string)
      this.typeChange(this.type)
      const address: string[] = []
      obj.city && (address[0] = obj.city)
      obj.region && (address[1] = obj.region)
      obj.address = address
    } else {
      this.type = 1
      this.typeChange(this.type)
      const address: string[] = []
      obj.province && (address[0] = obj.province)
      obj.city && (address[1] = obj.city)
      obj.region && (address[2] = obj.region)
      obj.address = address
    }
    this.submitForm = obj
    this.submitType = 'edit'
  }

  getList(): void {
    api.memberAddressList().then(res => {
      this.memberAddressList = res.data
    })
  }

  tableDom(): JSX.Element {
    return <el-table
      data={this.memberAddressList}
      style={{
        width: '100%',
        marginTop: '30px'
      }}
      border={true}
    >
      {this.tableList.map(item => <el-table-column
        prop={item.prop}
        align="center"
        label={item.label}
        v-slots={item.slot}
      />)}
    </el-table>
  }

  formDom(): JSX.Element {
    return <el-form
      ref="form"
      rules={this.formRules}
      model={this.submitForm}
      label-width="100px"
    >
      <el-row gutter={50}>
        <el-col xs={24} sm={12} md={12} lg={12} xl={12}>
          <el-form-item prop="name" label="收货人">
            <el-input v-model={this.submitForm.name} clearable placeholder="请输入收货人名称"/>
          </el-form-item>
        </el-col>
        <el-col xs={24} sm={12} md={12} lg={12} xl={12}>
          <el-form-item prop="phoneNumber" label="手机号码">
            <el-input v-model={this.submitForm.phoneNumber} clearable placeholder="请输入您的手机号"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row gutter={50}>
        <el-col xs={24} sm={12} md={12} lg={12} xl={12}>
          <el-form-item prop="address" label="收货地址">
            <el-cascader
              filterable
              v-model={this.submitForm.address}
              options={this.optionsList}
              style="width: 100%"
              clearable
              props={{
                label: 'cityName',
                value: 'cityName'
              }}
            />
          </el-form-item>
          <el-form-item prop="postCode" label="邮递区号">
            <el-input v-model={this.submitForm.postCode} clearable placeholder="请输入邮递区号"/>
          </el-form-item>
        </el-col>
        <el-col xs={24} sm={12} md={12} lg={12} xl={12}>
          <el-form-item prop="detailAddress" label="详细地址">
            <el-input type="textarea" v-model={this.submitForm.detailAddress} clearable placeholder="请输入详细地址信息，如街道、社区 楼栋号门牌号等信息"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span={24}>
          <div class="address-bottom">
            {this.submitType === 'edit' && <div onClick={this.cancel}>取消</div>}
            <div onClick={this.submit}>保存</div>
          </div>
        </el-col>
      </el-row>
    </el-form>
  }

  resetClear(): void {
    const __form = this.$refs.form as any
    __form.resetFields()
  }

  cancel(): void {
    this.submitType = 'add'
    this.resetClear()
  }

  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        const obj = Object.assign({}, this.submitForm)
        if(this.type === 1) {
          obj.province = obj.address?.[0] || ''
          obj.city = obj.address?.[1] || ''
          obj.region = obj.address?.[2] || ''
        } else {
          obj.province = this.getProvinceName || ''
          obj.city = obj.address?.[0] || ''
          obj.region = obj.address?.[1] || ''
        }
        delete obj.address
        this.submitType === 'add'
          ? this.submitAdd(obj)
          : this.submitEdit(obj)
      } else {
        return false
      }
    })
  }

  submitAdd(form: UmsAddress): void {
    delete form.id
    api.memberAddressAdd(form).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '添加地址成功'
      })
      this.getList()
      this.resetClear()
    })
  }
  submitEdit(form: UmsAddress): void {
    api.memberAddressUpdate(form).then(() => {
      ElNotification({
        type: 'success',
        title: '成功',
        message: '修改地址成功'
      })
      this.getList()
      this.resetClear()
    })
  }

  typeChange(e: number): void {
    this.type = e
    switch (this.type) {
      case 1:
        this.optionsList = this.ChinaList
        break
      case 2:
        this.optionsList = this.HongkongList
        break
      case 3:
        this.optionsList = this.MacaoList
        break
      case 4:
        this.optionsList = this.TaiwanList
        break
      default:
    }
  }

  get getProvinceName(): string {
    switch(this.type) {
      case 2:
        return '香港特别行政区'
      case 3:
        return '澳门特别行政区'
      case 4:
        return '台湾省'
      default:
        return ''
    }
  }
  getTypeName(name: string): number {
    switch(name) {
      case '香港特别行政区':
        return 2
      case '澳门特别行政区':
        return 3
      case '台湾省':
        return 4
      default:
        return 1
    }
  }

  goBack(): void {
    const name = this.$route.query?.back as string
    const ids = this.$route.query?.ids
    this.$router.push({
      name,
      query: {
        ids
      }
    })
  }

  created(): void {
    this.getJson()
  }

  render(): JSX.Element {
    return <div class="address">
      <div class="address-header" onClick={this.goBack}>
        <i class="el-icon-arrow-left"/>
        <span>返回</span>
      </div>
      <div class="address-title">{this.submitType === 'add' ? '新增' : '修改'}收货地址</div>
      <div class="address-choose">

        <div>
          {/* <i class="el-icon-warning" /> */}
          <span style="color:red">当前配送至:</span>
        </div>
        <el-select
          v-model={this.type}
          onChange={this.typeChange}
        >
          <el-option label="中国大陆" value={1} />
          <el-option label="中国香港" value={2} />
          <el-option label="中国澳门" value={3} />
          <el-option label="中国台湾" value={4} />
        </el-select>
      </div>
      {this.formDom()}
      {this.tableDom()}
    </div>
  }

  mounted(): void {
    this.getList()
    if(this.$route.query.id) {
      // 获取地址详情
      api.memberAddress(+this.$route.query.id).then(res => {
        this.addressEdit(res.data)
      })
    }
  }
}

export interface UmsAddress {
  city?:string // 城市
	defaultStatus?:number // 是否为默认
	detailAddress?:string // 详细地址(街道)
	id?:number
	memberId?:number
	name?:string // 收货人名称
	phoneNumber?:string
	postCode?:string // 邮政编码
	province?:string // 省份/直辖市
	region?: string // 区
  address?: string[]
}
